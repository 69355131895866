.input-label {
  display: block;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 30px;
}

textarea {
  font-family: inherit;
  resize: none;
  width: 95%;
  background-color: rgb(221, 225, 255);
  border: none;
  border-radius: 10px;
  min-height: 100px;
  padding: 10px;
}

button {
  padding: 10px;
  margin-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #212535;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: larger;
}

button:hover {
  background-color: #303753;
}

button:active {
  background-color: #0a0d19;
}

