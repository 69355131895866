.paper {
  width: 297mm;
  height: 205mm;
  padding: 5mm;
  /* border: solid .5px rgb(90, 90, 90); */
}

.layout {
  display: grid;
  grid-template-columns: 8cm 8cm 8cm 5cm;
  overflow: hidden;
  color: #212535;
  /* row-gap: 0.5px; */
  column-gap: 0.5px;
}

.label {
  width: 8cm;
  height: 5cm;
  border: solid .5px rgb(128, 128, 128);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rotate {
  grid-row: span 2;
  transform: rotate(90deg) translate(1.5cm, 1cm);
}



.label-title{
    border-top: solid 2px #212535;
    border-bottom: solid 2px #212535;
    width: 80%;
    padding: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
}

.label-icon {
    padding-bottom: 5px;
}

.label-detail{
    padding-top: 10px;
    text-align: center;
    font-size: small;
}

@media print {
  @page {
    size: landscape;
  }
}
